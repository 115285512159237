<template>
    <el-dialog v-model="isShowDeivce" width="1000px">
        <div class="table">
            <div class="table-head">
                <span class="table-title">已绑定设备列表</span>
                <span class="table-btn">
                    <el-button @click="deviceList" type="primary">
                        <el-icon style="color:#fff">
                            <Plus />
                        </el-icon>
                        <span style="margin-left:15px">绑定设备</span>
                    </el-button>
                </span>
            </div>
            <c-table ref="deviceListRef" :tableDataUrl="tableDataUrl" :border="true" :isLoadData="false">
                <el-table-column type="index" width="55" label="序号" :align="'center'" />
                <el-table-column label="设备序列号">
                    <template #default="scope">
                        <div class="icon-box">
                            <el-tooltip effect="dark" :content="scope.row.serial_number" placement="top-start">
                                <span class="text-overflow">{{scope.row.serial_number}}</span>
                            </el-tooltip>
                            <span class="copy icon">
                                <el-icon @click="copyWord(scope.row.serial_number)">
                                    <CopyDocument />
                                </el-icon>
                            </span>
                            <!-- <span class="copyed icon" v-else>
                            <el-icon>
                                <Check />
                            </el-icon>
                        </span> -->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="设备密钥">
                    <template #default="scope">
                        <div class="icon-box">
                            <el-tooltip effect="dark" :content="scope.row.secret" placement="top-start">
                                <span class="text-overflow">{{scope.row.secret}}</span>
                            </el-tooltip>
                            <span class="copy icon">
                                <el-icon @click="copyWord(scope.row.secret,'secret')">
                                    <CopyDocument />
                                </el-icon>
                            </span>
                            <!-- <span class="copyed icon" v-else>
                            <el-icon>
                                <Check />
                            </el-icon>
                        </span> -->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="机器码">
                    <template #default="scope">
                        <span v-if="scope.row.mark">
                            {{scope.row.mark}}
                        </span>
                        <span v-else>
                            -
                        </span>
                    </template>
                </el-table-column>>
                <el-table-column label="设备分类">
                    <template #default="scope">
                        <el-tag>{{deviceCategories[scope.row.device_category_id].name}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="设备型号">
                    <template #default="scope">
                        <el-tag type="warning">
                            {{deviceCategories[scope.row.device_category_id].device_types[scope.row.device_type_id]
                            ? deviceCategories[scope.row.device_category_id].device_types[scope.row.device_type_id].name
                            :deviceCategories[scope.row.device_category_id].name}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间">
                    <template #default="scope">
                        {{timeFormat(scope.row.created_at)}}
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-popconfirm title="确认解除绑定吗？" @confirm="unbind(scope.row)">
                            <template #reference>
                                <el-link type="primary">解绑</el-link>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </c-table>
        </div>
    </el-dialog>
    <el-dialog v-model="isShowDeviceList">
        <template #header>
            请选择需要绑定的设备
        </template>
        <div class="deviceTable">
            <c-table ref="unBindDeviceListRef" :tableDataUrl="bindDeviceTableDataUrl" :isLoadData="false"
                @selectionRows="selectionRows">
                <el-table-column type="selection" width="50" />
                <el-table-column type="index" width="55" label="序号" :align="'center'" />
                <el-table-column label="设备序列号">
                    <template #default="scope">
                        <div class="icon-box">
                            <el-tooltip effect="dark" :content="scope.row.serial_number" placement="top-start">
                                <span class="text-overflow">{{scope.row.serial_number}}</span>
                            </el-tooltip>
                            <span class="copy icon">
                                <el-icon @click="copyWord(scope.row.serial_number)">
                                    <CopyDocument />
                                </el-icon>
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="设备密钥">
                    <template #default="scope">
                        <div class="icon-box">
                            <el-tooltip effect="dark" :content="scope.row.secret" placement="top-start">
                                <span class="text-overflow">{{scope.row.secret}}</span>
                            </el-tooltip>
                            <span class="copy icon">
                                <el-icon @click="copyWord(scope.row.secret,'secret')">
                                    <CopyDocument />
                                </el-icon>
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="机器码">
                    <template #default="scope">
                        <span v-if="scope.row.mark">
                            {{scope.row.mark}}
                        </span>
                        <span v-else>
                            -
                        </span>
                    </template>
                </el-table-column>>
                <el-table-column label="设备分类">
                    <template #default="scope">
                        <el-tag>{{deviceCategories[scope.row.device_category_id].name}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="设备型号">
                    <template #default="scope">
                        <el-tag type="warning">
                            {{deviceCategories[scope.row.device_category_id].device_types[scope.row.device_type_id]
                            ? deviceCategories[scope.row.device_category_id].device_types[scope.row.device_type_id].name
                            :deviceCategories[scope.row.device_category_id].name}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间">
                    <template #default="scope">
                        {{timeFormat(scope.row.created_at)}}
                    </template>
                </el-table-column>
            </c-table>
        </div>
        <template #footer>
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import cTable from '@/components/CustomTable'
import { ref, defineExpose, nextTick, getCurrentInstance } from 'vue'
import { Plus, CopyDocument } from '@element-plus/icons-vue'
import { timeFormat } from '@/func/time'
import { ElMessage } from 'element-plus'
const { proxy } = getCurrentInstance()
const isShowDeivce = ref(false)
const isShowDeviceList = ref(false)

const deviceListRef = ref(null)
const unBindDeviceListRef = ref(null)

const tableDataUrl = ref(null)
// const bindDeviceTableDataUrl = ref(null)
const bindDeviceTableDataUrl = proxy.$api.agent.device.unBindDeviceList

const deviceCategories = ref([])

const agentInfo = ref(null)

const deviceList = () => {
    isShowDeviceList.value = true
    nextTick(() => {
        unBindDeviceListRef.value.setCustomLoad(true)
        unBindDeviceListRef.value.setParams({ is_bind_merchant: 0 })
    })
}

const cancel = () => {
    isShowDeviceList.value = false
}
const newDeviceSelectRows = ref([])
const selectionRows = (rows) => {
    newDeviceSelectRows.value = rows
}

const submit = () => {
    const deviceIds = []
    newDeviceSelectRows.value.forEach(element => {
        deviceIds.push(element.id)
    })

    proxy.$api.agent.device.bindDevices(agentInfo.value.id, { device_ids: deviceIds, id: agentInfo.value.id }).then(r => {
        if (r.status == 200) {
            deviceListRef.value.refresh()
            isShowDeviceList.value = false
        } else {
            ElMessage({ message: r.error.message, type: 'error' })
        }
    })
}

const unbind = (device) => {
    proxy.$api.agent.device.unBindDevices(device.agent_id, { device_ids: [device.id], id: device.agent_id }).then(r => {
        if (r.status == 200) {
            deviceListRef.value.refresh()
        } else {
            ElMessage({ message: r.error.message, type: 'error' })
        }
    })
}

const copyWord = (word) => {
    let tag = document.createElement('textarea');
    tag.setAttribute('id', 'cp_hgz_input');
    tag.value = word;
    document.getElementsByTagName('body')[0].appendChild(tag);
    document.getElementById('cp_hgz_input').select();
    document.execCommand('copy');
    document.getElementById('cp_hgz_input').remove();

    ElMessage({ message: '复制成功', type: 'success' })
}

const open = (agent) => {
    isShowDeivce.value = true
    agentInfo.value = agent
    proxy.$api.merchant.device.deviceCategory().then(r => {
        if (r.status == 200) {
            r.data.forEach((element) => {
                let deviceTypes = []
                element.device_types.forEach(elem => {
                    deviceTypes[elem.id] = elem
                })
                element.device_types = deviceTypes
                deviceCategories.value[element.id] = element
            })
        }
    })

    nextTick(() => {
        const url = '/agent/agents/' + agent.id + '/devices'
        deviceListRef.value.setRequestUrl(url)
        deviceListRef.value.setCustomLoad(true)
        deviceListRef.value.setParams({ id: agent.id })
    })
}
defineExpose({ open })
</script>
<style lang="less" scoped>
.icon-box {
    display: flex;
    flex-direction: row;

    .text-overflow {
        width: 50%;
        overflow: hidden;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
    }

    .copy {
        color: #409EFF !important;
        cursor: pointer;
    }

    .copyed {
        color: #52c41a !important;
    }

    .icon {
        font-size: 14px !important;

    }
}
</style>