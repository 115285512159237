<template>
    <el-dialog v-model="isShowRate" width="1000px" @closed="close">
        <template #header>
            成本费率配置
        </template>
        <div v-loading="loading">
            <el-tabs :model-value="currentTabIndex" type="border-card" @tab-change="changeTab">
                <el-tab-pane v-for="(channel, index) in systemChannel" :label="channel.name" :key="channel"
                    :name="index">
                    <template v-if="agentSetMethods.get(channel.id)">
                        <payment-method ref='methods' :methods="agentSetMethods.get(channel.id).payment_methods">
                        </payment-method>
                    </template>
                    <template v-else>
                        <payment-method ref='methods' :methods="channel.payment_methods"></payment-method>
                    </template>
                </el-tab-pane>
            </el-tabs>
        </div>
        <template #footer>
            <el-button type="primary" @click="save">保存设置</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import paymentMethod from '../../components/payment_method'
import { ref, defineExpose, getCurrentInstance } from 'vue'
import { nextTick } from 'process';
import { ElMessage } from 'element-plus';
const { proxy } = getCurrentInstance()
const isShowRate = ref(false)
const loading = ref(true)
const agentInfo = ref(null)
const systemChannel = ref(null)
const agentChannel = ref(null)
const agentSetMethods = ref(null)
const currentChannelId = ref(null)
const currentTabIndex = ref(0)

const changeTab = (index) => {
    loading.value = true
    currentTabIndex.value = index
    currentChannelId.value = systemChannel.value[index].id
    nextTick(() => {
        proxy.$refs.methods[index].formatForm()
    })

    loading.value = false
}

const selectPayMethod = (currentChannel) => {
    currentChannelId.value = currentChannel.id
    loading.value = false
}

const classifyChannel = () => {
    if (agentChannel.value == '') {
        loading.value = false
        return ''
    }

    let agentChannelMap = new Map
    agentChannel.value.forEach(agentMethod => {
        if (agentMethod.name && agentMethod.code) { // 过滤格式不正确的item
            let channel = {}

            if (agentChannelMap.has(agentMethod.payment_channel_id)) {
                channel = agentChannelMap.get(agentMethod.payment_channel_id)
                channel.payment_methods.push(agentMethod)
            } else {
                channel = {
                    id: agentMethod.payment_channel_id,
                    payment_methods: [agentMethod]
                }
            }

            agentChannelMap.set(agentMethod.payment_channel_id, channel)
        }
    })

    systemChannel.value.forEach(systemChannel => {
        if (agentChannelMap.has(systemChannel.id)) {
            const agentChannel = agentChannelMap.get(systemChannel.id)
            if (!agentChannel) {
                return
            }

            for (let i = 0; i < systemChannel.payment_methods.length; i++) {
                const systemChannelMethod = systemChannel.payment_methods[i]
                const agentChannelMethod = agentChannel.payment_methods[i]

                if (agentChannelMethod && systemChannelMethod.id == agentChannelMethod.payment_method_id) {
                    const pivot = {
                        cost: agentChannelMethod.cost,
                        extra_cost: agentChannelMethod.extra_cost,
                        payment_channel_id: agentChannelMethod.payment_channel_id,
                        payment_method_id: agentChannelMethod.payment_method_id
                    }

                    agentChannelMethod.pivot = pivot
                    agentChannelMethod.id = systemChannelMethod.id
                    agentChannelMethod.name = systemChannelMethod.name
                    agentChannelMethod.code = systemChannelMethod.code
                    agentChannelMethod.cost = systemChannelMethod.cost
                } else { // 如果当前支付方式系统有，代理商没有，就把系统的加入到代理商中
                    agentChannel.payment_methods.push(systemChannelMethod)
                    // console.log(systemChannel.name + '-' + agentChannelMethod.id, agentChannelMethod, systemChannelMethod)
                }
            }

            /**
             * 再循环代理商的支付方式，如果代理商的支付方式比系统的支付方式多，说明系统支付方式取消了某个或者多个支付方式
             * 要把代理商的对应的支付方式从支付集合中去除
             */
            let newPaymentMethods = []
            for (let i = 0; i < agentChannel.payment_methods.length; i++) {
                const systemChannelMethod = systemChannel.payment_methods[i]
                const agentChannelMethod = agentChannel.payment_methods[i]
                // console.log(systemChannel.name + '-' + agentChannelMethod.id, agentChannelMethod)

                if (systemChannelMethod && agentChannelMethod.id && systemChannelMethod.id) {
                    newPaymentMethods[i] = agentChannelMethod
                }
            }
            agentChannel.payment_methods = newPaymentMethods
            agentChannelMap.set(agentChannel.id, agentChannel)
        }
    })

    agentSetMethods.value = agentChannelMap
    console.log(agentSetMethods.value)
}

const save = () => {
    nextTick(() => {
        const form = proxy.$refs.methods[currentTabIndex.value].sendMethodForm()
        const params = {
            agent_id: agentInfo.value.id,
            payment_channel_id: currentChannelId.value,
            payment_methods: form
        }
        form && proxy.$api.agent.rate.saveRate(agentInfo.value.id, params).then(r => {
            if (r.status == 200) {
                ElMessage({ message: '保存成功', type: 'success' })
                currentTabIndex.value = 0
                currentChannelId.value = null
                isShowRate.value = false
            }
        })
    })
}

const close = () => {
    agentSetMethods.value = agentChannel.value = systemChannel.value = null
    currentTabIndex.value = 0
    currentChannelId.value = null
    loading.value = true
}

const open = (agent) => {
    proxy.$api.agent.getOne(agent.id).then(agentRes => {
        if (agentRes.status == 200) {
            agentChannel.value = agentRes.data.agent_rates
            proxy.$api.channel.paymentChannels().then(systemRes => {
                if (systemRes.status == 200) {
                    systemChannel.value = systemRes.data
                    systemChannel.value.sort((a, b) => {
                        return a.sort > b.sort ? a : b;
                    })
                    // 归类代理商支付方式到支付渠道
                    classifyChannel()
                    //默认打开第一个
                    selectPayMethod(systemChannel.value[0])
                }
            })
        }
    })


    agentInfo.value = agent
    isShowRate.value = true
}

defineExpose({ open })
</script>